// import { CircularProgress } from '@mui/material';
// import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
// import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
// import TextField from '@mui/material/TextField';
// import Typography from '@mui/material/Typography';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import * as React from 'react';
// import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import { BACKEND_URL } from '../../env';
// import Logo from "../../images/desertsdroppshiper.png";
// import LoginImage from '../../images/loginImage.jpg';
// import LoaderView from '../../components/Loader';
// import { Suspense } from 'react';

// const defaultTheme = createTheme();

// export default function SignInSide() {
//   const [password, setPassword] = useState("")
//   const [email, setEmail] = useState("")
//   const [loading, setLoading] = useState(false)

//   const navigate = useNavigate()

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     if (!email || !password) {
//       toast.error('Please Fill Input');
//     } else {
//       setLoading(true);
//       const myHeaders = new Headers();
//       myHeaders.append('Content-Type', 'application/json');

//       const raw = JSON.stringify({
//         email: email,
//         password: password,
//         isActive: true
//       });

//       const requestOptions = {
//         method: 'POST',
//         headers: myHeaders,
//         body: raw,
//         redirect: 'follow',
//       };

//       try {
//         const response = await fetch(
//           `${BACKEND_URL}/auth/signin`,
//           requestOptions
//         );
//         const result = await response.json();

//         setLoading(false);

//         if (result.status === 400 || result.error) {
//           toast.error(result.message);
//         } else {
//           // await localStorage.setItem('loginData', JSON.stringify(result));
//           navigate('/Otp', { state: email });
//           setEmail('');
//           setPassword('');
//         }
//       } catch (error) {
//         console.error('Error:', error);
//         toast.error('An error occurred. Please try again.');
//         setLoading(false);
//       }
//     }
//   };

//   return (
//     <ThemeProvider theme={defaultTheme}>
//       <Grid container component="main" sx={{ height: '100vh' }}>
//         <CssBaseline />
//         <Suspense fallback={<LoaderView />}>
//           <Grid
//             item
//             xs={false}
//             sm={4}
//             md={7}
//             sx={{
//               backgroundImage: `url(${LoginImage})`, // Corrected image reference
//               backgroundColor: (t) =>
//                 t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
//               backgroundSize: 'cover',
//               backgroundPosition: 'center',
//             }}
//             loading={"lazy"}
//           />
//         </Suspense>
//         <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
//           <Box
//             sx={{
//               my: 8,
//               mx: 4,
//               display: 'flex',
//               flexDirection: 'column',
//               alignItems: 'center',
//             }}
//           >
//             {/* <Avatar sx={{ m: 1 }}> */}
//             {/* <LockOutlinedIcon /> */}
//             <img src={Logo} alt='' width={300} height={100} />
//             {/* </Avatar> */}
//             <Typography component="h1" variant="h5" style={{ fontFamily: "Kanit" }}>
//               Sign in
//             </Typography>
//             <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
//               <TextField
//                 margin="normal"
//                 required
//                 fullWidth
//                 id="email"
//                 label="Email Address"
//                 name="email"
//                 autoComplete="email"
//                 autoFocus
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />
//               <TextField
//                 margin="normal"
//                 required
//                 fullWidth
//                 name="password"
//                 label="Password"
//                 type="password"
//                 id="password"
//                 autoComplete="current-password"
//                 value={password}
//                 onChange={(e) => setPassword(e.target.value)}
//               />
//               {/* <FormControlLabel
//                 control={<Checkbox value="remember" color="primary" />}
//                 label="Remember me"
//               /> */}
//               <Button
//                 type="submit"
//                 fullWidth
//                 variant="contained"
//                 sx={{ mt: 3, mb: 2, background: "#E86029" }}
//               // onClick={() => navigate("/Otp")}
//               >
//                 {loading ? <CircularProgress size={14} sx={{ color: "#fff" }} /> : "Login"}
//               </Button>
//               <Grid container>
//                 <Grid item xs>
//                   {/* <Link href="#" variant="body2">
//                     Forgot password?
//                   </Link> */}
//                 </Grid>
//                 <Grid item>
//                   <p style={{ fontFamily: "Kanit", fontSize: "14px" }}>
//                     {"Don't have an account? "} <a href='/Register' style={{ fontFamily: "Kanit", fontSize: "14px", textDecoration: "none" }}>Sign Up</a>
//                   </p>
//                 </Grid>
//               </Grid>
//               {/* <Copyright sx={{ mt: 5 }} /> */}
//             </Box>
//           </Box>
//         </Grid>
//       </Grid>
//     </ThemeProvider>
//   );
// }
import React, { useState } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { BACKEND_URL } from "../../env";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import Logo from "../../images/desertsdroppshiper.png";
import { Visibility, VisibilityOff } from "@mui/icons-material";
const LoginForm = () => {
  const [password, setPassword] = useState("")
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate()

  const handleSubmit = async () => {
    if (!email || !password) {
      toast.error('Please Fill Input');
    } else {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');

      const raw = JSON.stringify({
        email: email,
        password: password,
        isActive: true
      });

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };

      try {
        const response = await fetch(
          `${BACKEND_URL}/auth/signin`,
          requestOptions
        );
        const result = await response.json();

        setLoading(false);

        if (result.status === 400 || result.error) {
          toast.error(result.message);
        } else {
          // await localStorage.setItem('loginData', JSON.stringify(result));
          navigate('/Otp', { state: email });
          setEmail('');
          setPassword('');
        }
      } catch (error) {
        console.error('Error:', error);
        toast.error('An error occurred. Please try again.');
        setLoading(false);
      }
    }
  };
  return (
    <div className="login-container">
      <div className="login-wrapper">
        <div className="login-logo">
          <img src={Logo} alt='' width={230} height={80} />
        </div>
        <h2 className="login-heading">Login</h2>
        <div className="Login-input-div">
          <label className="lable">Email*</label>
          <div className="name-input-div">
            <input
              type="text"
              placeholder="Email"
              // className="register-input"
              className="register-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="Login-input-div">
          <label className="lable">Password*</label>
          <div className="name-input-div">
            <input
              placeholder="Password"
              // className="register-input"
              className="register-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
            />
            <span
              className="password-toggle-icon"
              onClick={() => setShowPassword(!showPassword)} // Toggle showPassword state
            >
              {showPassword ? <VisibilityOff /> : <Visibility />} {/* Show different icon */}
            </span>
          </div>
        </div>
        <div className="login-btn-container">
          <button className="login-btn" onClick={() => handleSubmit()}>{loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Login"}</button>
        </div>
        <div className="not-account">
          <p>Don't have an account? <a href="/Register">Register</a></p>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
