import "./index.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGift, faAngleRight, faPlug, faShoppingBag, faPlusSquare, faMinusSquare, faBars } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import CategorySidebar from "../CategorySidebar";
import { BACKEND_Image_URL } from "../../env";
function Category({ AllCategory }) {
    const [hoveredCategory, setHoveredCategory] = useState(null);
    const [showAllCategories, setShowAllCategories] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [hoverTimeout, setHoverTimeout] = useState(null);
    console.log(AllCategory, "AllCategory");

    const openSidebar = () => {
        setSidebarOpen(true);
    }

    const closeSidebar = () => {
        setSidebarOpen(false);
    }

    const categories = [
        { icon: faGift, text: "Gift & Toys", subcategories: ["Toys", "Gifts", "Games"] },
        { icon: faPlug, text: "Electronics", subcategories: ["Mobile", "Laptop", "Accessories"] },
        { icon: faPlug, text: "Electronics", subcategories: ["Mobile", "Laptop", "Accessories"] },
        { icon: faShoppingBag, text: "Bags & Shoes", subcategories: ["Handbags", "Shoes", "Backpacks"] },
        { icon: faShoppingBag, text: "Bags & Shoes", subcategories: ["Handbags", "Shoes", "Backpacks"] },
        { icon: faShoppingBag, text: "Bags & Shoes", subcategories: ["Handbags", "Shoes", "Backpacks"] },
        { icon: faShoppingBag, text: "Bags & Shoes", subcategories: ["Handbags", "Shoes", "Backpacks"] },
        { icon: faShoppingBag, text: "Bags & Shoes", subcategories: ["Handbags", "Shoes", "Backpacks"] },
        { icon: faShoppingBag, text: "Bags & Shoes", subcategories: ["Handbags", "Shoes", "Backpacks"] },
        { icon: faShoppingBag, text: "Bags & Shoes", subcategories: ["Handbags", "Shoes", "Backpacks"] },
        { icon: faShoppingBag, text: "Bags & Shoes", subcategories: ["Handbags", "Shoes", "Backpacks"] },
    ];

    const displayedCategories = showAllCategories ? categories : categories.slice(0, 8);

    const handleMouseEnter = (index) => {
        if (hoverTimeout) {
            clearTimeout(hoverTimeout);
            setHoverTimeout(null);
        }
        setHoveredCategory(index);
    };

    const handleMouseLeave = () => {
        const timeoutId = setTimeout(() => {
            setHoveredCategory(null);
        }, 300);
        setHoverTimeout(timeoutId);
    };

    return (
        <>
            <div className="category-container">
                <FontAwesomeIcon icon={faBars} className="menu-icon" onClick={() => openSidebar()} />
                <h2>ALL CATEGORIES</h2>
                <div className="category-main">
                    {AllCategory.map((category, index) => (
                        <div
                            key={index}
                            className="category"
                            onMouseEnter={() => {
                                category?.subcategories ? handleMouseEnter(index) : console.log("hello");
                            }}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                                <img src={
                                    category.Image.startsWith("category/http") || category.Image.startsWith("category/https")
                                        ? category.Image.replace("category/", "")
                                        : `${BACKEND_Image_URL}${category.Image}`
                                } className={"category_image"} />
                                {/* <FontAwesomeIcon icon={faGift} className="category-icon" /> */}
                                <p className="category-text">{category.name}</p>
                            </div>
                            {category?.subcategories ? <FontAwesomeIcon icon={faAngleRight} className="category-icon" /> : null}
                            {hoveredCategory === index && (
                                <div
                                    className="subcategories"
                                    onMouseEnter={() => handleMouseEnter(index)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    {category?.subcategories?.map((sub, subIndex) => (
                                        <p key={subIndex} className="subcategory-text">{sub}</p>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                    {AllCategory.length > 7 ? <div className="more-category-container" onClick={() => setShowAllCategories(!showAllCategories)}>
                        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                            <FontAwesomeIcon icon={showAllCategories ? faMinusSquare : faPlusSquare} className="more-category-icon" />
                            <p className="more-category-text">{showAllCategories ? "Close Category" : "More Category"}</p>
                        </div>
                    </div> : null}
                </div>
            </div>
            <CategorySidebar closeSidebar={closeSidebar} sidebarOpen={sidebarOpen} categories={AllCategory} />
        </>
    );
}

export default Category;
