// import * as React from 'react';
// import Avatar from '@mui/material/Avatar';
// import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Link from '@mui/material/Link';
// import Paper from '@mui/material/Paper';
// import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
// import Typography from '@mui/material/Typography';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import LoginImage from '../../images/loginImage.jpg'; // Corrected import
// import Logo from "../../images/desertsdroppshiper.png"
// import { useNavigate } from 'react-router-dom';

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

// const defaultTheme = createTheme();

// export default function Register() {
//   const navigate = useNavigate()
//   const handleSubmit = (event) => {
//     event.preventDefault();
//     const data = new FormData(event.currentTarget);
//     console.log({
//       email: data.get('email'),
//       password: data.get('password'),
//     });
//   };

//   return (
//     <ThemeProvider theme={defaultTheme}>
//       <Grid container component="main" sx={{ height: '100vh' }}>
//         <CssBaseline />
//         <Grid
//           item
//           xs={false}
//           sm={4}
//           md={7}
//           sx={{
//             backgroundImage: `url(${LoginImage})`, // Corrected image reference
//             backgroundColor: (t) =>
//               t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
//             backgroundSize: 'cover',
//             backgroundPosition: 'center',
//           }}
//         />
//         <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
//           <Box
//             sx={{
//               my: 8,
//               mx: 4,
//               display: 'flex',
//               flexDirection: 'column',
//               alignItems: 'center',
//             }}
//           >
//             {/* <Avatar sx={{ m: 1 }}> */}
//             {/* <LockOutlinedIcon /> */}
//             <img src={Logo} alt='' width={300} height={100} />
//             {/* </Avatar> */}
//             <Typography component="h1" variant="h5" style={{ fontFamily: "Kanit" }}>
//               Sign up
//             </Typography>
//             <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
//               <TextField
//                 margin="normal"
//                 required
//                 fullWidth
//                 id="UserName"
//                 label="User Name"
//                 name="UerName"
//                 autoFocus
//               />
//               <TextField
//                 margin="normal"
//                 required
//                 fullWidth
//                 id="email"
//                 label="Email Address"
//                 name="email"
//                 autoComplete="email"
//                 autoFocus
//               />
//               <TextField
//                 margin="normal"
//                 required
//                 fullWidth
//                 name="password"
//                 label="Password"
//                 type="password"
//                 id="password"
//                 autoComplete="current-password"
//               />
//               <TextField
//                 margin="normal"
//                 required
//                 fullWidth
//                 name="confirmpassword"
//                 label="Confirm Password"
//                 type="password"
//                 id="confirmpassword"
//                 autoComplete="current-password"
//               />
//               <TextField
//                 margin="normal"
//                 required
//                 fullWidth
//                 id="phone"
//                 label="Phone Number"
//                 name="phone"
//                 autoFocus
//               />
//               <TextField
//                 margin="normal"
//                 required
//                 fullWidth
//                 name="storeUrl"
//                 label="Store Url"
//                 type="text"
//                 id="storeUrl"
//               // autoComplete="current-password"
//               />
//               {/* <FormControlLabel
//                 control={<Checkbox value="remember" color="primary" />}
//                 label="Remember me"
//               /> */}
//               <Button
//                 type="submit"
//                 fullWidth
//                 variant="contained"
//                 sx={{ mt: 3, mb: 2, background: "#E86029" }}
//                 onClick={() => navigate("/Otp")}
//               >
//                 Sing up
//               </Button>
//               <Grid container>
//                 <Grid item xs>
//                   {/* <Link href="#" variant="body2">
//                     Forgot password?
//                   </Link> */}
//                 </Grid>
//                 <Grid item>
//                   <p style={{ fontFamily: "Kanit", fontSize: "14px" }}>
//                     {"You Have Already account? "} <a href='/Login' style={{ fontFamily: "Kanit", fontSize: "14px", textDecoration: "none" }}>Login</a>
//                   </p>
//                 </Grid>
//               </Grid>
//               {/* <Copyright sx={{ mt: 5 }} /> */}
//             </Box>
//           </Box>
//         </Grid>
//       </Grid>
//     </ThemeProvider>
//   );
// }
import { useNavigate } from "react-router-dom"
import "./index.css"
import { useState } from "react"
import { BACKEND_URL } from "../../env"
import { CircularProgress } from "@mui/material"
import { toast } from "react-toastify"
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Register() {
  const [storeName, setStoreName] = useState("")
  const [email, setEmail] = useState("")
  const [whatsapp, setWhatsapp] = useState("")
  const [cnic, setCnic] = useState("")
  const [bankNumber, setBankNumber] = useState("")
  const [accountTitle, setAccountTitle] = useState("")
  const [accountNumber, setAccountNumber] = useState("")
  const [iban, setIban] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate()
  const handleSubmit = async () => {
    if (!email) {
      toast.error('Please Email Input');
      return
    }
    if (!password) {
      toast.error('Please Password Input');
      return
    }
    if (!storeName) {
      toast.error('Please Store Name Input');
      return
    }
    if (!storeName) {
      toast.error('Please Password Input');
      return
    }
    if (!whatsapp) {
      toast.error('Please Whatsapp Input');
      return
    }
    if (!cnic) {
      toast.error('Please Cnic Input');
      return
    }
    if (!bankNumber) {
      toast.error('Please Bank Number Input');
      return
    }
    if (!accountTitle) {
      toast.error('Please Account Tite Input');
      return
    }
    if (!accountNumber || !iban || !confirmPassword) {
      toast.error('Please Account Number Input');
      return
    }
    if (!iban || !confirmPassword) {
      toast.error('Please Account iban Input');
      return
    }
    if (!confirmPassword) {
      toast.error('Please Account Confirm Password Input');
      return
    }
    if (whatsapp.length !== 11) {
      toast.error('WhatsApp number must be exactly 11 digits.');
      return;
    }
    if (iban.length !== 24) {
      toast.error('WhatsApp number must be exactly 11 digits.');
      return;
    }
    if (password !== confirmPassword) {
      toast.error('Passwords do not match.');
      return;
    }
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    const raw = JSON.stringify({
      email: email,
      password: password,
      whatsapp: whatsapp,
      storeName: storeName,
      cnic: cnic,
      bankNumber: bankNumber,
      accountTitle: accountTitle,
      accountNumber: accountNumber,
      iban: iban,
      isActive: true
    });

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    try {
      const response = await fetch(
        `${BACKEND_URL}/auth/signup`,
        requestOptions
      );
      const result = await response.json();

      setLoading(false);

      if (result.status === 400) {
        toast.error(result.message);
      } else {
        await localStorage.setItem('loginData', JSON.stringify(result));
        setEmail('');
        setPassword('');
        setAccountNumber('');
        setAccountTitle('');
        setBankNumber('');
        setCnic('');
        setConfirmPassword('');
        setIban('');
        setStoreName('');
        setWhatsapp('');
        navigate('/login');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please try again.');
      setLoading(false);
    }

  };
  return (
    <div className="register-main-container">
      <h1 className="reward-main-heading">Seller Registration Form</h1>
      <div className="register-form-container">
        <div className="form-data">
          <div className="register-input-div">
            <label className="lable">Store Name*</label>
            <div className="name-input-div">
              <input
                type="text"
                placeholder="Store Name"
                // className="register-input"
                className="register-input"
                value={storeName}
                onChange={(e) => setStoreName(e.target.value)}
              // required
              />
            </div>
          </div>
          <div className="register-input-div">
            <label className="lable">E-mail*</label>
            <div className="name-input-div">
              <input
                type="text"
                placeholder="E-mail"
                // className="register-input"
                className="register-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-data">
          <div className="register-input-div">
            <label className="lable">Whatsapp*</label>
            <div className="name-input-div">
              <input
                type="type"
                placeholder="Whatsapp Number - 03160117561 nospace"
                minLength={11}
                maxLength={11}
                className="register-input"
                value={whatsapp}
                onChange={(e) => {
                  if (e.target.value.length <= 11) {
                    setWhatsapp(e.target.value.replace(/\D/g, '')); // Only allow numbers
                  }
                }}
              // onChange={(e) => setWhatsapp(e.target.value)}
              />
            </div>
          </div>
          <div className="register-input-div">
            <label className="lable">CNIC*</label>
            <div className="name-input-div">
              <input
                type="number"
                placeholder="CNIC"
                // className="register-input"
                className="register-input"
                value={cnic}
                onChange={(e) => setCnic(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-data">
          <div className="register-input-div">
            <label className="lable">Bank Number*</label>
            <div className="name-input-div">
              <input
                type="number"
                placeholder="Bank Number"
                // className="register-input"
                className="register-input"
                value={bankNumber}
                onChange={(e) => setBankNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="register-input-div">
            <label className="lable">Account Title*</label>
            <div className="name-input-div">
              <input
                type="text"
                placeholder="Account Title"
                // className="register-input"
                className="register-input"
                value={accountTitle}
                onChange={(e) => setAccountTitle(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="form-data">
          <div className="register-input-div">
            <label className="lable">Account Number*</label>
            <div className="name-input-div">
              <input
                type="number"
                placeholder="Account Number"
                // className="register-input"
                className="register-input"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="register-input-div">
            <label className="lable">IBAN*</label>
            <div className="name-input-div">
              <input
                type="number"
                placeholder="IBAN 24 Digits Without Space"
                className="register-input"
                value={iban}
                onChange={(e) => {
                  if (e.target.value.length <= 24) {
                    setIban(e.target.value.replace(/\D/g, '')); // Only allow numbers
                  }
                }}
                // onChange={(e) => setIban(e.target.value)}
                minLength={24}
                maxLength={24}
              />
            </div>
          </div>
        </div>
        <div className="form-data">
          <div className="register-input-div">
            <label className="lable">Create Password*</label>
            {/* <div className="name-input-div"> */}
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Create Password"
              // className="register-input"
              className="register-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span
              className="password-register-toggle-icon"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </span>
            {/* </div> */}
          </div>
          <div className="register-input-div">
            <label className="lable">Re-Enter Password*</label>
            {/* <div className="name-input-div"> */}
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Re-Enter Password"
              // className="register-input"
              className="register-input"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <span
              className="password-register-toggle-icon"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </span>
            {/* </div> */}
          </div>
        </div>
        <div className="register-btn-container">
          <button className="register-btn" onClick={() => handleSubmit()}>{loading ? <CircularProgress size={20} sx={{ color: "#fff" }} /> : "Register"}</button>
        </div>
      </div>
    </div>
  )
}
export default Register