import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { useState } from "react";
import { Accordion } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import Footer from "../../components/Footer";
import LoginPopup from "../../components/LoginPopup";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/sidebar";
import { withAppContext } from '../../context';
import { BACKEND_Image_URL } from '../../env';
import Link from "../../images/link.svg";
import NotFound from "../../images/notFound.webp";
import Warn from "../../images/warn.svg";
import "./index.css";

function ProductDetail({ WishlistData, AllCategory, instagramData }) {
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loginModal, setLoginModal] = useState(false);
    const location = useLocation();
    
    // State to track the main selected image
    const [selectedImage, setSelectedImage] = useState(
        location.state?.images?.[0]
            ? (location.state.images[0].startsWith("products/http") || location.state.images[0].startsWith("products/https")
                ? location.state.images[0].replace("products/", "")
                : `${BACKEND_Image_URL}${location.state.images[0]}`)
            : NotFound
    );

    const openSidebar = () => {
        setSidebarOpen(true);
    };

    const closeSidebar = () => {
        setSidebarOpen(false);
    };

    const openLoginModel = () => {
        setLoginModal(!loginModal);
    };

    const handleDownload = async () => {
        const imageUrls = location.state?.images || [];
        const videoUrls = location.state?.videos || [];

        // Download images
        for (let url of imageUrls) {
            try {
                const response = await fetch(url.startsWith("products/http") || url.startsWith("products/https")
                    ? url.replace("products/", "")
                    : `${BACKEND_Image_URL}${url}`);
                const blob = await response.blob();
                const link = document.createElement('a');
                const objectUrl = URL.createObjectURL(blob);
                link.href = objectUrl;
                link.download = url.substring(url.lastIndexOf('/') + 1); // Set filename
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(objectUrl);
            } catch (error) {
                console.error("Failed to download image:", error);
            }
        }

        // Download videos
        for (let url of videoUrls) {
            try {
                const response = await fetch(
                    url.startsWith("products/http") || url.startsWith("products/https")
                        ? url.replace("products/", "")
                        : `${BACKEND_Image_URL}${url}`
                );
                const blob = await response.blob();
                const link = document.createElement('a');
                const objectUrl = URL.createObjectURL(blob);
                link.href = objectUrl;
                link.download = url.substring(url.lastIndexOf('/') + 1); // Set filename
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(objectUrl);
            } catch (error) {
                console.error("Failed to download video:", error);
            }
        }
    };

    const descriptionHtml = location.state?.description
        ? (() => {
            try {
                const parsedDescription = location.state.description.startsWith('{')
                    ? JSON.parse(location.state.description)
                    : location.state.description;
                return typeof parsedDescription === 'object'
                    ? stateToHTML(convertFromRaw(parsedDescription))
                    : parsedDescription;
            } catch (error) {
                console.error("Failed to parse description:", error);
                return "";
            }
        })()
        : "";

    return (
        <>
            {loginModal ? <LoginPopup overlayClick={openLoginModel} /> : null}
            <Navbar openSidebar={openSidebar} WishlistData={WishlistData} AllCategory={AllCategory} />
            <Sidebar closeSidebar={closeSidebar} sidebarOpen={sidebarOpen} />
            <div className='product-detail-main-container'>
                <div className='product-detail'>
                    <div className='product-detail-images-container'>
                        <div className='mini-image-container'>
                            {location.state?.images?.map((e, i) => (
                                <img
                                    key={i}
                                    src={e?.startsWith("products/http") || e?.startsWith("products/https")
                                        ? e?.replace("products/", "")
                                        : `${BACKEND_Image_URL}${e}`}
                                    alt=''
                                    onClick={() => setSelectedImage(
                                        e?.startsWith("products/http") || e?.startsWith("products/https")
                                            ? e?.replace("products/", "")
                                            : `${BACKEND_Image_URL}${e}`
                                    )}
                                />
                            ))}
                            {location.state?.videos?.map((e, i) => (
                                <iframe
                                    key={i}
                                    src={e?.startsWith("products/http") || e?.startsWith("products/https")
                                        ? e?.replace("products/", "")
                                        : `${BACKEND_Image_URL}${e}`}
                                    alt=''
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            ))}
                        </div>
                        <div className='product-detail-big-image'>
                            <img src={selectedImage} alt="" />
                        </div>
                    </div>
                    <div className='product-detialed'>
                        <h6 className='product-detail-name'>{location.state?.title}</h6>
                        <p className='product-detail-price'> {location.state?.price} Aed</p>
                        <div className='product-detail-download' onClick={handleDownload}>
                            <img src={Link} alt="Download" />
                            <p>Download Images &amp; Videos</p>
                        </div>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <img src={Warn} alt="Download" />
                                    Product Description
                                </Accordion.Header>
                                <Accordion.Body dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </div>
            <Footer instagramData={instagramData} />
        </>
    );
}

export default withAppContext(ProductDetail);
