import { faHeart, faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";
import React from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BACKEND_Image_URL, BACKEND_URL } from '../../env'; // Import backend URL
import NotFound from "../../images/notFound.webp";
import "./index.css";

function FeatureProduct({ onClick, AllProducts, getWishlist }) {
    const navigate = useNavigate();
    const storedLoginData = JSON.parse(localStorage.getItem("loginData"));

    // Function to handle adding a product to the wishlist
    const handleAddToWishlistClick = async (product) => {
        if (storedLoginData && storedLoginData.token) {
            try {
                const response = await axios.post(`${BACKEND_URL}/wishlist`, {
                    productId: product.id
                }, {
                    headers: {
                        token: storedLoginData.token
                    }
                });

                toast.success("Product Added To Wishlist")
                getWishlist()
                if (response.status === 200) {
                    console.log("Product added to wishlist:", product);
                }
            } catch (error) {
                console.error("Error adding product to wishlist:", error);
            }
        } else {
            onClick(); // Open login modal if the user is not logged in
        }
    };

    const handleAddToCartClick = (product) => {
        if (storedLoginData && storedLoginData.token) {
            let cart = JSON.parse(localStorage.getItem("cart")) || [];
            const productIndex = cart.findIndex((item) => item.id === product.id);

            if (productIndex === -1) {
                cart.push({ ...product, quantity: 1 });
                toast.success("Successfully Add to Cart")
            } else {
                cart[productIndex].quantity += 1;
                toast.success("This Product Already Add to Cart")
            }

            localStorage.setItem("cart", JSON.stringify(cart));
            console.log("Product added to cart:", product);
        } else {
            onClick(); // Open login modal
        }
    };

    return (
        <div className="wishlits-container">
            <h1 className="wishlist-main-heading">Feature Products</h1>
            <div className='wishlist-product-container'>
                {AllProducts.map((e, i) => e.FeatureProduct ? (
                    <div key={i} className='wishlits-product-main'>
                        <img
                            src={e.images?.length > 0 ?
                                e.images[0].startsWith("products/http") || e.images[0].startsWith("products/https")
                                    ? e.images[0].replace("products/", "")
                                    : `${BACKEND_Image_URL}${e.images[0]}` : NotFound
                            }
                            // src={`${BACKEND_Image_URL}${e.images[0]}`}
                            className='wishlist-product-image'
                            onClick={() => navigate("/ProductDetail", { state: e })}
                        />
                        <p className='wishlist-product-name'>{e.title}</p>
                        <p className='wishlist-product-price'>{e.price} AED</p>
                        <div style={{ display: "flex" }}>
                            <div className="wishlist-addcart" onClick={() => handleAddToCartClick(e)}>
                                <FontAwesomeIcon icon={faShoppingBag} className="trending-icon" />
                                <p>Add to Cart</p>
                            </div>
                            <div
                                className="trending-addcart"
                                style={{ marginLeft: 10 }}
                                onClick={() => handleAddToWishlistClick(e)} // Call wishlist function
                            >
                                <FontAwesomeIcon icon={faHeart} className="trending-icon" />
                                {/* <p>Add to Wishlist</p> */}
                            </div>
                        </div>
                    </div>
                ) : null)}
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px 0" }}>
                <button className='feature-showall' onClick={() => navigate("/allProduct")}>Show All</button>
            </div>
        </div>
    );
}

export default FeatureProduct;
