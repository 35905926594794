import { faHeart, faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import AllProductCategory from '../../components/AllProductCategory';
import Footer from "../../components/Footer";
import LoginPopup from "../../components/LoginPopup";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/sidebar";
import SortFilter from '../../components/SortFilter';
import { withAppContext } from '../../context';
import { BACKEND_Image_URL, BACKEND_URL } from '../../env';
import NotFound from "../../images/notFound.webp";
import "./index.css";
function AllProduct({ AllCategory, GetAllCategories, getTags, instagramData, AllProducts, GetAllProducts, getWishlist, WishlistData }) {
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [loginModal, setLoginModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState();
    const [sortOption, setSortOption] = useState('');
    const [fromPrice, setFromPrice] = useState('');
    const [toPrice, setToPrice] = useState('');

    const openSidebar = () => setSidebarOpen(true);
    const closeSidebar = () => setSidebarOpen(false);
    const openLoginModel = () => setLoginModal(!loginModal);

    useEffect(() => {
        GetAllCategories();
        getTags();
        GetAllProducts();
        getWishlist();
    }, []);



    let filteredProducts = selectedCategory
        ? AllProducts?.filter(product => product.category === selectedCategory)
        : AllProducts;

    // Sort products based on the selected sort option
    if (sortOption === "Best Selling") {
        filteredProducts = filteredProducts.filter(product => product.bestSelling);
    } else if (sortOption === "Features") {
        filteredProducts = filteredProducts.filter(product => product.FeatureProduct);
    } else if (sortOption === "Price, low to high") {
        filteredProducts = filteredProducts.sort((a, b) => a.price - b.price);
    } else if (sortOption === "Price, high to low") {
        filteredProducts = filteredProducts.sort((a, b) => b.price - a.price);
    } else if (sortOption === "Alphabetically, A-Z") {
        filteredProducts = filteredProducts.sort((a, b) => a.title.localeCompare(b.title));
    } else if (sortOption === "Alphabetically, Z-A") {
        filteredProducts = filteredProducts.sort((a, b) => b.title.localeCompare(a.title));
    } else if (sortOption === "Date, old to new") {
        filteredProducts = filteredProducts.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    } else if (sortOption === "Date, new to old") {
        filteredProducts = filteredProducts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    }
    const highestPrice = AllProducts?.reduce((max, product) => product.price > max ? product.price : max, 0);
    const storedLoginData = JSON.parse(localStorage.getItem("loginData"));

    const handleAddToCartClick = (product) => {
        if (storedLoginData && storedLoginData.token) {
            // Retrieve the current cart from localStorage or initialize an empty array
            let cart = JSON.parse(localStorage.getItem("cart")) || [];

            // Check if the product is already in the cart
            const productIndex = cart.findIndex((item) => item.id === product.id);

            if (productIndex === -1) {
                // Add new product to the cart
                cart.push({ ...product, quantity: 1 });
                toast.success("Successfully Add to Cart")
            } else {
                // If the product is already in the cart, update its quantity
                cart[productIndex].quantity += 1;
                toast.success("This Product Already Add to Cart")
            }

            // Save the updated cart back to localStorage
            localStorage.setItem("cart", JSON.stringify(cart));

            console.log("Product added to cart:", product);
        } else {
            openLoginModel(); // Open login modal
        }
    };
    const handleAddToWishlistClick = async (product) => {
        if (storedLoginData && storedLoginData.token) {
            try {
                const response = await axios.post(`${BACKEND_URL}/wishlist`, {
                    productId: product.id
                }, {
                    headers: {
                        token: storedLoginData.token
                    }
                });

                toast.success("Product Added To Wishlist")
                getWishlist()
                if (response.status === 200) {
                    console.log("Product added to wishlist:", product);
                }
            } catch (error) {
                console.error("Error adding product to wishlist:", error);
            }
        } else {
            openLoginModel(); // Open login modal
        }
    }

    if (fromPrice !== '' || toPrice !== '') {
        filteredProducts = filteredProducts.filter(product => {
            const price = product.price;
            const from = fromPrice !== '' ? parseFloat(fromPrice) : 0;
            const to = toPrice !== '' ? parseFloat(toPrice) : Infinity;
            return price >= from && price <= to;
        });
    }
    return (
        <>
            {loginModal && <LoginPopup overlayClick={openLoginModel} />}
            <Navbar openSidebar={openSidebar} WishlistData={WishlistData} AllCategory={AllCategory} />
            <Sidebar closeSidebar={closeSidebar} sidebarOpen={sidebarOpen} />
            <div className='Allproduct-main-container'>
                <div className="main-heading-sort-filter">
                    <div className='allproduct-main-heading-container'>
                        <h1 className="allproduct-main-heading">Products</h1>
                    </div>
                    <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end", width: "40%" }}>
                        <SortFilter onSelect={setSortOption} />
                    </div>
                </div>
                <div className="category-slider">
                    <AllProductCategory toPrice={toPrice} fromPrice={fromPrice} setToPrice={setToPrice} setFromPrice={setFromPrice} highestPrice={highestPrice} AllCategory={AllCategory} setSelectedCategory={setSelectedCategory} selectedCategory={selectedCategory} />
                    <div className='all-products-main'>
                        {filteredProducts.map((e, i) => (
                            <div key={i} className='all-product-main'>
                                <img
                                    src={e.images?.length > 0 ?
                                        e?.images[0]?.startsWith("products/http") || e?.images[0]?.startsWith("products/https")
                                            ? e?.images[0]?.replace("products/", "")
                                            : `${BACKEND_Image_URL}${e?.images[0]}` : NotFound
                                    }
                                    // src={`${BACKEND_Image_URL}${e?.images[0]}`}
                                    className='wishlist-product-image'
                                    onClick={() => navigate("/ProductDetail", { state: e })}
                                />
                                <p className='wishlist-product-name'>{e.title}</p>
                                <p className='wishlist-product-price'>{e.price} Aed</p>
                                <div style={{ display: "flex" }}>
                                    <div className="wishlist-addcart" onClick={() => handleAddToCartClick(e)}>
                                        <FontAwesomeIcon icon={faShoppingBag} className="trending-icon" />
                                        <p>Add to Cart</p>
                                    </div>
                                    <div className="trending-addcart" style={{ marginLeft: 10 }} onClick={() => handleAddToWishlistClick(e)} >
                                        <FontAwesomeIcon icon={faHeart} className="trending-icon" />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer instagramData={instagramData} />
        </>
    );
}

export default withAppContext(AllProduct);
